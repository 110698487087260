import React, { useState } from "react";

function Password({updateToggleState}) {

    const [valid, setValid] = useState(false);

    const [values, setValues] = useState({
        password: ""
    });

    var correctPassword = 'goteam';

    const handlePasswordChange = (event) => {
        setValues({...values, password: event.target.value})
        setSubmitted(false)
    }

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        if(values.password === correctPassword) {
            setValid(true);
            handleToggle();
        }
        setSubmitted(true)
    }
    const handleToggle = (event) => {
        updateToggleState(prevState => !prevState);
    }
    return (
      <div className="pw-form-wrap lock-scroll">
        <h2>Enter password</h2>
        <form className="form-password"
                onSubmit={handleSubmit}>
                {submitted && valid ? <div>Thanks!!</div> : null}                
            <input
                onChange={handlePasswordChange}
                value={values.password}
                className="form-field"
                placeholder="Enter password"
                name="password" />
                {submitted && !valid ? (
                    <div className="incorrect-pw">Password is incorrect</div>
                ) : null}

            <button
                className="form-button"
                type="submit">Enter</button>
        </form>
      </div>
    );
  }
  
  export default Password;