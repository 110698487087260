import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './sass/index.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import AboutPage from './pages/about';
import CaseStudy from './pages/case_studydesign';
import CaseWorkbench from './pages/case_workbench';
import CaseEditChecks from './pages/case_editchecks';
import CaseBookRiot from './pages/case_bookriot';
import ScrollToTop from './components/ScrollToTop';
import ScrollToAnchor from './components/scrollToAnchor';


function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <ScrollToAnchor />
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/case_studydesign" element={<CaseStudy />} />
            <Route path="/case_workbench" element={<CaseWorkbench />} />
            <Route path="/case_editchecks" element={<CaseEditChecks />} />
            <Route path="/case_bookriot" element={<CaseBookRiot />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
