import React, { useState } from "react";
import '../sass/app.scss';
import '../sass/home.scss';
import Nav from '../components/nav';
import WalkthroughVideo from '../imgs/case/study_build/video-1.mp4';
import CaseImgSDTM from '../imgs/case/workbench/wb-sdtm.png';
import CaseImgTrans from '../imgs/case/workbench/wb-field-transformations.png';
import CaseImgDatasets1 from '../imgs/case/workbench/wb-datasets-1.png';
import CaseImgDatasets2 from '../imgs/case/workbench/wb-datasets-2.png';



function CaseWorkbenchContent() {

    const [isToggled, setIsToggled] = useState(true);

    const updateToggleState = (newState) => {
      setIsToggled(newState);
    };

  return (
    <div className="app-wrap case">
        <div className="page-wrap">                    
            <div className='case-study-header case-workbench'>
                <Nav />
                <h1>Launching a new data transformation suite: <span>No-code data transformations, optimized for clinical study data </span></h1>
            </div>
            <div className='case-content-wrap'>
                <div className='case-content case-text-wrap'>
                    <h2>Background</h2>                   
                   <p>This was an interesting new project to create a 'no-code' solution to transform and normalize clinical trial datasets to prepare for statistical analysis and FDA submission. The current method to do this is by manually drawing out the notes on forms and applying SaS code. This is a very expensive process and can result in delays in getting valuable metrics. Having a way to simplify the code, and set up rules that can apply to data as it comes in, opposed to batch processing of records, allows for companies to setup the programming and reuse moving forward.</p>
                    <h2>Discovery</h2>
                    <h3>Understanding the current processes and challenges</h3>
                    <p>Together with the product team we conducted an interview with a clinical programmer who manages this process currently. The process required very manual application of codes and logistical management to transform and deliver the output data. This is also done in batches, multiple for one study, where mistakes can easily get lost.</p>
                    <p><b>Current challenges</b></p>
                    <ul className="order-list">                            
                        <li>Transformations are currently conducted in SaS programming code - a challenge to learn and library to maintain</li>    
                        <li>Transformations conducted in batches as data is collected in time. (e.g.; once per week)</li>    
                        <li>Delays to getting valuable insights while transformations are pending</li>
                    </ul>
                    <p><b>Opportunities</b></p>
                    <ul className="order-list">                            
                        <li>A WYSIWYG or no-code UI solution to enable non-technical users</li>    
                        <li>Data supported by streaming service, no batch processing, no delays</li>    
                        <li>Data readily available in streams - outputs connected to analysis tools</li>
                    </ul>
                </div>
            </div>
            <div className='case-content-wrap'>
                <div className='case-content case-text-wrap'>
                    <h2>Solutions</h2>
                    <p>At a high level the project can be divied into 3 pillars of solutions</p>
                    <ol className="order-list">
                        <li><b>SDTM mapping.</b> SDTM is an industry standard format for submitting data to the FDA as well as using data for analysis. </li>
                        <li><b>Field transformations</b> - Applying transformations on specific fields. (e.g.; combining dates and times into a date-time variable)</li>
                        <li><b>Dataset transformations</b> - Joining, grouping, transposing and other functions to transform and combine multiple datasets.</li>
                    </ol>
                </div>
            </div>
            <div className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">                    
                        <h3>Solution: SDTM Mapping</h3>
                        <div className="case_img">
                            <img src={CaseImgSDTM} />
                        </div>
                        <p>Map source datasets to the clinical trial standard format, SDTM.</p>
                    </div>                
                </div>
            </div>    
            <div className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">                    
                        <h3>Solution: Field transformations</h3>                    
                        <p>Conduct field transformations like concat, trim, and many other to get the format you need</p>                    
                        <div className="case_img">
                            <img src={CaseImgTrans} />
                        </div>
                        <p className="case_img_subtext">A common field transformation is combining a date and time field into a new date-time variable. In this UI users can apply the functions on the left and preview the the formula schematic on the right as well as the data to ensure results.</p>
                    </div>                
                </div>
            </div>
            <div className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">                    
                        <h3>Solution: Dataset transformations</h3>
                        <div className="case_img">
                            <img src={CaseImgDatasets1} />
                        </div>
                        <p className="case_img_subtext">In this example the user would like to union two datasets together that both have vital signs data -- a vital signs form and vital signs data collected through lab test. The UI on the left allows the user to setup the dataset structure (often referred to as a "schema"), and preview the before and after datasets on the right.</p>
                    </div>
                </div>
            </div>
            <div className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">         
                        <div className="case_img">
                            <img src={CaseImgDatasets2} />
                        </div>
                        <p className="case_img_subtext">Once applied. User's can see the lineage of how the data was combined, similar to how the lineage is shown for field transformations.</p>
                    </div>                
                </div>
            </div>
            <div className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <h2>Next steps</h2>
                        <p>We are excited to announce the first beta launch of our new data transformation solution in June 2024. Several 'early adopter' clients will have access to this beta version and will work closely with us to identify and resolve any issues, ensuring that all their goals are met.</p>
                    </div>
                </div>
            </div>           
            <div className="website-copywrite case">
                <span>Website by Andrew / 2024 ©</span>
            </div>
        </div>        
    </div>
  );
}

export default CaseWorkbenchContent;