import { Link } from "react-router-dom";
import ScrollToAnchor from "./scrollToAnchor";

function Nav() {
    return (
      <div className="nav">
        <ul>
            <li>
                <Link to='/home#recent'>work</Link>
            </li>
            <li>
                <Link to='/about'>about</Link>
            </li>
            <li>
                <Link to='/home#contact'>contact</Link>
            </li>
        </ul>
      </div>
    );
  }
  
  export default Nav;