import React, { useState } from "react";
import '../sass/app.scss';
import '../sass/home.scss';
import Nav from '../components/nav';
import ImgStudyBuildJourney from '../imgs/case/study_build/study-build-journey.png';
import CaseBuildPersonaStats from '../imgs/case/study_build/study-build-persona-stats.png';
import EditCheckOld from '../imgs/case/study_build/edit-check-old.png';
import EditCheckNew from '../imgs/case/study_build/edit-check-new.png';
import Workflow1 from '../imgs/case/study_build/workflow_1.png';
import Workflow2 from '../imgs/case/study_build/workflow_2.png';
import StandardData1 from '../imgs/case/study_build/standard-data-1.png';
import StandardData2 from '../imgs/case/study_build/standard-data-2.png';
import Library1 from '../imgs/case/study_build/library-1.png';
import Library2 from '../imgs/case/study_build/library-2.png';

import WalkthroughVideo from '../imgs/case/study_build/video-1.mp4';

function CaseStudyContent() {

    const [isToggled, setIsToggled] = useState(true);

    const updateToggleState = (newState) => {
      setIsToggled(newState);
    };

  return (    
    <div className="app-wrap case">
        <div className="page-wrap">                    
            <div className='case-study-header case-study-build'>
                <Nav />
                <h1>Streamlining Clinical Studies: <span>Integrating diverse data sources into a single, user-friendly experience.</span></h1>
            </div>
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <h2>Background</h2>
                        <p>Successful clinical studies depend on accurate data from patients who meet specific enrollment criteria. Verified monitoring devices have increased enrollment by allowing more patients to participate without frequent site visits.</p>
                        <p>While these solutions have had positive impact on enrollment, they've also raised the costs of standardizing the data due to source variations and ultimately delayed valuable insights of ongoing studies.</p>
                        <h2>Discovery</h2>
                        <h3>The users</h3>
                        <p><b>Study builder</b> - The main user is a study builder. A role that requires cross department communication and whose primary task is to translate the protocol to system requirements and setting up the data collection systems. Before moving into production there are rigorous testing and reviews that must be met before publishing.</p>
                        <p><b>Data manager</b> - The data manager is responsible for ensuring the data is properly named so that downstream functions properly work. They serve mostly as a reviewer role during the build.  Their other task  includes confirming clean data as its collected, a necessary step to move the data into lock mode, and ready for analysis.</p>
                        <p><b>Statistician</b> - The statistician also plays a reviewer role, ensuring the required data is set up to be collected.</p>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-5 offset-sm-1">
                        <div className="case_img case_persona">
                            <img src={CaseBuildPersonaStats}></img>
                    </div>
                    </div>
                    <div className="col-sm-5">       
                        <h3>Gathering all study builders</h3>
                        <p>In clinical trials there are 4 common systems to collect data, </p>
                            <ol className="order-list">
                                <li>EDC system - A system to collect all of the main forms. Data is typically entered during or post patient visits.</li>    
                                <li>ECOA system - a patient facing application to log daily health observations and notes.</li>
                                <li>Consent system - Docuements for the patient to sign.</li>
                                <li>Wearable devices - Assigned to patients to collect vitals such as heart rate.</li>
                            </ol>
                        <p>Due the the complexity of each in some organizations the skills to configure these systems are split amongst the team of many. One strategic goal is to create a uniform experience that lowers the entry barrier and enables more builders to configure anything.</p>                
                    </div>
                </div>
            </section>
            <section className="case-section">
            <div className="row">                
                <div className="col-sm-5 offset-sm-1">
                    <div className="text-block">
                        <h3>Core problems</h3>
                        <ul className="unordered-list">
                            <li>Configuring studies is too complex and requires years of experiece to learn the quirks of legacy systems.</li>
                            <li>Review and approval workflows occurs in offline systems, increasing timelines</li> 
                            <li>Transcribing a protocol is not easy; misinterpretations results in costly amendments</li>
                            <li>Data collected is disconnected when captured by different sources, making downstream efforts to normalized and prepare for analytics expensive and time consuming.</li>
                        </ul>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="text-block">
                        <h3>Strategic goals</h3>
                        <ul className="unordered-list">
                            <li>Improve study build accuracy and avoid costly amendments</li>
                            <li>Reduce study build timelines</li> 
                            <li>Simplify the experience to enable new users and lower the cost to build a study</li>
                            <li>Standardizing data across sources to be ready for analysis immediately, reducing necessity of clinical programming downstream</li>
                        </ul>
                    </div>
                </div>
            </div>
            </section>
            <section className="case-section">
            <div className='case-content-wrap'>
                <div className='case-content case-text-wrap'>
                    <h3>Mapping the study build experience</h3>
                    <p>Note the section on the right labeled 'Post Data Capture.' While this is not part of the study build experience, it is crucial for understanding the significant negative impacts of a poorly designed study and identifying opportunities to improve quality.</p>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-sm-10 offset-sm-1">
                    <div className="case_img">
                        <img src={ImgStudyBuildJourney}></img>
                        <p><b>User journey - Pain points, opportunities, solutions</b></p>
                        <p className="case_img_subtext">Product and user workshop to map our the study build experience. Listing pain points and opportunities.</p>                    
                    </div>
                </div>
            </div>
            </section>
            <section className="case-section">
                <div className='row'>
                    <div className='col-sm-6 offset-sm-3'>
                        <h2>Solutioning</h2>
                        <p>Referring back to our strategic goals, we brainstormed ideas to address the main issues and develop measurable results for testing our solutions. I helped the team shift from specific solutions to outcomes, enabling broader thinking and more diverse idea contributions.</p>
                        <br /><br />
                        
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>                        
                        <div className="case-big-text">
                            Issue: Configuring studies is complex and requires years of experiece to know the quirks of legacy systems
                        </div>
                        <h3>Solution</h3>
                        <p>Simplify the edit check builder, a feature used to create logic to run data validation checks. A configuration that is responsible for 50% or more of a study build timeline due to its complexity.</p>                
                    </div>
                </div>       
            </section>
            <section className="case-section">
            <div className="col-2">
                <div className="col-2-center">
                    <div className="case_img case_persona half-width">
                            <img className="case_img" src={EditCheckOld}></img>
                        <h3>Issues with the existing edit check builder:</h3>
                            <ol className="order-list">
                                <li>Uses “post-fix” notation, a data centric expression writing format that places the operatetors at the end. E.g.; The logic of, 'when heart rate is greater than 160...' is written as 'Heart rate, 160, greater than...', not soo complex by itself, but increases exponentially as the logical expressions are nested with 'ANDs' and 'ORs'. </li>
                                <li> The current edit check builder is used for more than just data validations, relaying heavily on the expression building capability even more.</li>
                                <li>Due to its complexity, aligning the logic with requirements based on the protocl can be a challenge. </li>
                            </ol>
                        </div>
                        <div className="case_img case_persona half-width">
                            <img className="case_img" src={EditCheckNew}></img>
                            <h3>Solution:</h3>
                            <p>An edit check builder that creates expressions in natural language, enabling new users to create expressions based on the requirement and preparing the logic to be more ready for Ai generation.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text">
                            Issue: Review and approval workflows occurs in offline systems, increasing timelines by requiring lengthy meetings to review and test.
                        </div>
                    </div>
                </div>      
            </section>
            <section className="case-section img-preview">
                <div className="col-2">
                    <div className="col-2-center">
                        <div className="case_img case_persona half-width">
                            <img src={Workflow1}></img>
                        </div>
                        <div className="case_img case_persona half-width">
                            <img src={Workflow2}></img>                        
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <h2>Solution:</h2>
                        <p>A configurable workflow that enables the reviewer roles to review and approve study build obejcts within the application. With an additional restriction that all objects must be approved to publish to production.</p>
                    </div>
                </div>    
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text with-subtext">
                            Issue: Transcribing a clinical study protocol* is open to interpretation and as a result, challenging to adhere to standards.
                        </div>
                        <p className="case_img_subtext">* A protocol is a 50-100 page document defining the conduct of a clinical study. The method of documentation varys company to company.</p>
                    </div>
                </div>      
            </section>
            <section className="case-section img-preview">
                <div className="col-2">
                    <div className="col-2-center">
                        <div className="case_img case_persona half-width">
                            <img src={StandardData1}></img>
                            <h3>Defining data before configuring the data collection systems:</h3>
                            <ol className="order-list">
                                <p>Allow users to focus on the data collect, ignoring any nuance distraction of how the collection system is configured.</p>
                                <p>With an approval workflow, allow multiple users to collaborate in determining the correct data to collect.</p>
                            </ol>
                        </div>
                        <div className="case_img case_persona half-width">
                            <img src={StandardData2}></img>                   
                            <h3>Adding standard objects from a library:</h3>
                            <ol className="order-list">
                                <p>When data is defined up front, accelerate data collection systems by confirming and quickly adding the data as source fields.</p>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text">
                            Issue: Data collected is disconnected when captured by different sources, making downstream efforts to normalized and prepare for analytics expensive and time consuming.
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section img-preview">
                <div className="col-2">
                    <div className="col-2-center">
                        <div className="case_img case_persona half-width">
                            <img src={Library1}></img>
                            <h3>Common groups of fields or measurements are saved as resuable objects, branded as 'shapes' within the platform.</h3>
                            <p>Source data fields are mapped to common clinical measurements, short-named as "shapes" in study data.</p>
                        </div>
                        <div className="case_img case_persona half-width">
                            <img src={Library2}></img>                   
                            <h3>Shape data is automatically mapped to downstream data formats such as SDTM, a clinical trial standard.</h3>
                            <p>By mapping the sources up front, downstream efforts to re-join and normalize data are greatly reduced.</p>
                        </div>
                    </div>
                </div>
            </section>         
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text">
                            Issue: Builders today work in isolation to configuring the data capture modules (EDC, ECOA, Consent, Wearable sensors) 
                        </div>
                    </div>
                </div>    
            </section>  
            <section className="case-section img-preview">
                <div className="case_video_demo">
                    <video width="auto" height="auto" controls >
                        <source src={WalkthroughVideo} type="video/mp4"/>
                    </video>
                </div>
            </section>
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <h3>Solution</h3>
                        <p>A centralized hub for study design where all modules for study build are configured and published together - each with data connected to the standardized study data, ready for downstream analysis.</p>                    
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className='case-content-wrap'>
                    <div className='case-content case-text-wrap'>
                        <h2>Summary</h2>
                        <p>I was privileged to be a part of such a large and complex project that provided a plethora of opportunities into exciting new spaces. </p>
                        <p>I'm thankful for the contributions of the team: the internal experts of the legacy systems who spent many hours teaching the existing processes and trying out new ideas and concepts, the clients who were willing to contribute and be part of the future, and the product team who embraced a collaborative learning environment to accelerate innovation.</p>
                        <p></p>
                    </div>
                </div>
            </section>
            <div className="website-copywrite case">
                <span>Website by Andrew / 2024 ©</span>
            </div>
        </div>        
    </div>
  );
}

export default CaseStudyContent;