import React, { useState } from "react";
import '../sass/app.scss';
import '../sass/home.scss';
import Nav from '../components/nav';
import ImgECFlowchart from '../imgs/case/edit-checks/EC-Criteria.png';
import ImgECbefore from '../imgs/case/edit-checks/EC-legacy.png';
import ImgECNew from '../imgs/case/edit-checks/EC-new.png';


function CaseEditCheckContent() {

    const [isToggled, setIsToggled] = useState(true);

    const updateToggleState = (newState) => {
      setIsToggled(newState);
    };

  return (    
    <div className="app-wrap case">
        <div className="page-wrap">                    
            <div className='case-study-header case-edit-checks'>
                <Nav />
                <h1>Edit checks: <span>Modernizing Data Validation Checks in Clinical Trials</span></h1>
            </div> 
            <section className="case-section">
                <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <h2>Background</h2>
                            <p>Data validation checks, or “edit checks”, are conditional rules built around the data collected to perform actions when certain criteria of the data are met. It most cases used as a warning that an error may have occurred collecting data.</p>
                            <p>Building the rules can be difficult and expensive for a couple of reasons,<br />
                                1. The sheer complexity of the conditional, “if this equals this and that equals that then....” <br /><br />
                                2. The data being compared may come different sources at different times.
                            </p>
                            <p>As a business goal, we wanted to lower the difficulty of creating the rules to enable more users to build themselves.</p>
                            <h3>User research findings</h3>
                            <p>1. The first and most obvious problem was the syntax of how the conditional is created in the UI. The syntax was in post-fix notation, and expression syntax that puts the operator(s) at the end of the equation. This becomes increasingly difficult when multiple conditionals are added up. <br /><br />
                                2. There were many instances where the user needed to manually input text string data, when that data was already collected and available. A completely unnecessary risk that could invalidate the check, when it could be avoided by using data in the system.
                            </p>                    
                        </div>
                </div>
           </section>
           <section className="case-section">
            <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text">
                            Issues and pain points:
                            <ol>
                                <li>Edit checks syntax is not clear and intuitive - ("post-fix notation", operators are added at the end)</li>
                                <li>Learning how to create edit checks requires a lot of training and experience</li>
                                <li>Not in a format that could easily setup for generative AI</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="section-break-visual">
                            <hr /><hr /><hr />
                        </div>
                        <div className="case-big-text">
                            Solutions:
                            <ol>
                                <li>Update the syntax to be more readable and align with how the requirements and read and understood</li>
                                <li>Create a dynamic experience that focuses on one element at a time, focusing the decision making process and providing a clear route of actions.</li>
                                <li>Set up a system that prepares for a generative AI exprience</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">                     
                        <h3>Updating the user flow: A dynamic conditional rule builder</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="case_img">
                            <img src={ImgECFlowchart} />
                        </div>
                        <p className="case_img_subtext">Example conditional: If systolic blood pressure is greater than 125mmhg, open an alert task.</p>                                          
                        <p className="case_img_subtext">This new user flow provides two solutions. It uses natural language of how conditionals are understood and builds out dynamically based on each selection.</p>
                    </div>
                </div>
            </section>
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">       
                        <h2>The high fidelity solution</h2>
                        <p>1. Organizing the edit checks into a clear and legible structure enables the system and users to use generative AI to further enhance the process.</p>
                        <p>2. A simple to use system enables new users who are familiar with writing the requirement to create the edit checks themselves instead of hiring expensive clinical programmers.</p>
                    </div>
                </div>
            </section>
            <section>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1">
                        <h3>Legacy system</h3>
                        <div className="case_img">
                            <img src={ImgECbefore} /> 
                        </div>                 
                    </div>
                </div>
            </section>
            <section>
                <div className="row">
                    <div className="col-sm-10 offset-sm-1">
                        <h3>New solution</h3>
                        <div className="case_img">
                            <img src={ImgECNew} /> 
                        </div>                 
                    </div>
                </div>
            </section>
            <div className="website-copywrite case">
                <span>Website by Andrew / 2024 ©</span>
            </div>
        </div>        
    </div>
  );
}

export default CaseEditCheckContent;