import React, { useState } from "react";
import '../sass/app.scss';
import '../sass/home.scss';
import Nav from '../components/nav';
import BookRiot1 from '../imgs/case/bookriot/book-flip.jpg';
import BookRiot2 from '../imgs/case/bookriot/dots.png';
import BookRiot3 from '../imgs/case/bookriot/final-logo.jpg';
import YoutubeEmbed from "../components/youtubembed";


function CaseBookRiotContent() {

    const [isToggled, setIsToggled] = useState(true);

    const updateToggleState = (newState) => {
      setIsToggled(newState);
    };

  return (    
    <div className="app-wrap case">
        <div className="page-wrap">                    
            <div className='case-study-header case-book-riot'>
                <Nav />
                <h1>Book Riot: <span>Creating a YouTube bumper animation</span></h1>
            </div> 
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <h2>Background</h2>          
                        <p>In 2020 I was hired to update the bumper video, a short video shown at the begging of youtube videos, for the Book Review company Book Riot.</p>                              
                        <h2>Requirements</h2>
                        <ol>
                            <li>Create a more vibrant and fun version of the bumper animation than the current version</li>
                            <li>Timing - video must be between 3-5 seconds</li>
                            <li>All media included must be self created or from creative commons licensing</li>
                        </ol>
                        <h2>Process</h2>
                        <p>My first step in the process was brainstorming as many concepts about book reading as possible. One of my early graphic design professors emphasized the importance of having a concept behind a design. This approach helps convey a message and engages the user in thinking about the underlying connections, consciously or subconsciously, rather than just focusing on the graphics. Even a mediocre design can be effective if it has a strong concept.</p>
                        <h2>Assembling the concepts</h2>
                        <p>Using After Effects, I set up panels on a bright white canvas to flip back and forth across the screen, simulating the action of turning pages and creating a fun sense of movement. For sound design I added the sounds of flipping through pages, keeping the volume low to build for the second portion of the video.</p>
                    </div>
                </div>     
           </section> 
           <section className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="case_img">
                            <img src={BookRiot1} />
                        </div>                        
                    </div>
                </div>
            </section>       
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <p>Once that was finished, I added the logo with a background of dots, simulating halftone print. The logo's popping animation creates a wave effect, causing the dots to increase and decrease in size along the wave. While it's difficult to describe an ambient sound, I went with something that was uplifting and positive.</p>
                    </div>
                </div>
            </section>   
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="case_img">
                            <img src={BookRiot2} />
                        </div>                        
                    </div>
                </div>
            </section>       
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <p>A final wave out removes all the dots of the brand color, leaving the elegant black and white version of the logo.</p>
                    </div>
                </div>
            </section>   
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <div className="case_img">
                            <img src={BookRiot3} />
                        </div>                        
                    </div>
                </div>
            </section>       
            <section className="case-section">
                <div className="row">
                    <div className="col-sm-8 offset-sm-2">
                        <h2>Preview</h2>
                        <p>An example video where the animation was used</p> <br />
                        <YoutubeEmbed embedId="ZR3EllMkioY" />  
                    </div>
                </div>
            </section>
            <div className="website-copywrite case">
                <span>Website by Andrew / 2024 ©</span>
            </div>
        </div>        
    </div>
  );
}

export default CaseBookRiotContent;