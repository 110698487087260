import '../sass/app.scss';
import '../sass/home.scss';
import Nav from '../components/nav';
import AboutPortrait  from '../imgs/about/portrait-1.jpg';
import BarleyDog  from '../imgs/about/barley.jpg';
import aboutHeaderGrapihc from '../imgs/about/portrait-about-graphic.svg';

function AboutPage() {
  return (
    <div className="app-wrap">
        <div className='row justify-content-end nav-spacing-top'>
          <div className='col-sm-2'>
            <Nav />
          </div>
        </div>
        <div className='container'>
        <section className="case-section">
          <div className='about-page-wrap'>        
            <div className='row'>            
              <div className='col-sm-4 offset-sm-1'>
                <img src={AboutPortrait} className='portrait-img' />
              </div>
              <div className='col-sm-5 intro-section'>
                <div className='about-header-graphic'>
                    <img src={aboutHeaderGrapihc} className='about-header-graphic'></img>                  
                </div>
                <h1>A litte about myself</h1>
                <p>Hello! I'm Andrew. Born in Dallas Texas, raised in Cincinnati Ohio, and currently living in Brooklyn, NY.  My interest in visual and interactive design traces back to my early memories of watching music videos and playing the super nintendo, and was enriched int he 2010s through an interactive web design education program where I practiced graphic design, motion design, and web development. </p>
                <p>My first few gigs were a hybrid of web design and delopment. First with creating a marketing site and marketing emails, followed by software design at Croson Engineering where in addition to learning how GitHub and the terminal works, how software UI design translates to development, because I was responsible for all of it. </p>
                <p>At Medidata Solutions, my position transitioned to full scope UX/Product designer. In addition to providing high fidelity UI designs that adhere and contribute to an evolving design system, I lead teams in user strategy, establishing goals and success metrics to enable an entire team of great minds. </p>                
              </div>     
              </div>     
          </div>
        </section>
        <section className="case-section">
          <div className='row'>
            <div className='col-sm-4 offset-sm-1'>
              <p>In my free time I'm hiking somewhere in the northeast, checking out antique stores, playing a round of disc golf, testing fujifilm film simulations, and walking my 3-year old dachshund Barley.</p>
              <p>As a creative outlet I write and record music with my band and friends in Brooklyn.</p>
            </div>
            <div className='col-sm-4'>
              <img src={BarleyDog} className='portrait-img' />
            </div>
          </div>
        </section>
        <div className="website-copywrite case">
            <span>Website by Andrew / 2024 ©</span>
        </div>            
      </div>
    </div>
  );
}

export default AboutPage;