import React, { useState } from "react";
import '../sass/home.scss';
import Password from '../components/password';
import LogoImg from '../imgs/svg-logo.svg';
import SectionTitleWork from '../imgs/section-title-work.svg';
import ThumbBookRiot from '../imgs/case/bookriot/bookriot-thumb.png';
import ThumbWorkbench from '../imgs/case/workbench/workbench-thumb.jpg';
import ThumbEditchecks from '../imgs/case/edit-checks/edit-checks-thumb.png';

import ThumbStudyBuild from '../imgs/case/study_build/build-thumb-test-1.jpg';
import HeroImage from '../imgs/landing/hero-artwork.svg';

import { Link } from "react-router-dom";
// import ScrollToAnchor from "./components/scrollToAnchor";

function HomePage() {

  return (
    <div className="app-wrap">
        <div className='hero'>  
          <div className='hero-border'>
            <div className='header-items'>              
              <div className='logo'>
                  <img src={LogoImg} alt="" />
              </div>
              <div className="nav">
                <ul>
                    <li>
                    {/* <ScrollToAnchor /> */}
                        <Link to='/home#recent'>work</Link>
                    </li>
                    <li>
                        <Link to='/about'>about</Link>
                    </li>
                    <li>
                        <Link to='/home#contact'>contact</Link>
                    </li>
                </ul>
              </div>
            </div>
            <div className='hero-content'>
              <div className='hero-content-wrap'>
                  <h1>User-driven product design
                    <div className="hero-lines">
                      <div className="style-line"></div>
                      <div className="style-line"></div>
                      <div className="style-line"></div>
                      <div className="style-line"></div>
                    </div>                  
                    <span>I unite teams around understanding user problems to foster collaborative innovation.</span>
                  </h1>
                  <div className="hero-artwork">
                    <img src={HeroImage} />
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='case-listing-wrap' >
          <div className='section-title' id="recent">
            <h2>Recent work</h2>
            <span className="recent-work-subtitle">key projects I've played a role in leading to success</span>
            <div className="section-title-lines">
              <div className="style-line"></div>
              <div className="style-line"></div>
              <div className="style-line"></div>
              <div className="style-line"></div>
            </div>
          </div>
          <div className='work-section'>
            <div className='work-section-border'>
              <div className="work-thumb">
                  {/* <div className='work-thumb-overlay'></div> */}
                <div className='work-thumb-img'>
                  <img src={ThumbStudyBuild} alt="" />
                </div>
                <div className='case-thumb-links'>
                  <div className='work-thumb-title'>
                    <h2>Streamlining clinical studies: Standardizing data for efficacy</h2>
                  </div>
                  <Link className="form-button case-button" to='/case_studydesign'>
                      View case study
                  </Link>
                </div>
              </div>
              <div className='work-thumb'>
                <div className='work-thumb-overlay'></div>
                <div className='work-thumb-img'>
                  <img src={ThumbWorkbench} alt="" />
                </div>
                <div className='work-thumb-title'>
                  <h2>Launching a no-code data transformation suite</h2>
                </div>
                <Link className="form-button case-button" to='/case_workbench'>
                  View case study
                </Link>
              </div>
              <div className='work-thumb'>
                <div className='work-thumb-overlay'></div>
                <div className='work-thumb-img'>
                  <img src={ThumbEditchecks} alt="" />
                </div>
                <div className='work-thumb-title'>
                  <h2>Modernizing data validation checks in clinical trials</h2>
                </div>
                <Link className="form-button case-button" to='/case_editchecks'>
                    View case study
                </Link>
              </div>
              <div className='work-thumb'>
                <div className='work-thumb-overlay'></div>
                <div className='work-thumb-img'>
                  <img src={ThumbBookRiot} alt="" />
                </div>
                <div className='work-thumb-title'>
                  <h2>Intro animation for a video book review series</h2>
                </div>
                <Link className="form-button case-button" to='/case_bookriot'>
                    View case study
                </Link>
              </div>          
            </div>
          </div>
          <div className='section-title' id="contact">
            <h2>contact</h2>
            <div className="section-title-lines">
              <div className="style-line"></div>
              <div className="style-line"></div>
              <div className="style-line"></div>
              <div className="style-line"></div>
            </div>
          </div>
          <div className='contact_section'>
              <span className="home_email">andrewbakerguy@gmail.com</span>            
              <div className='color-block'></div>
          </div>
          <div className="website-copywrite">
            <span>Website by Andrew // 2024 ©</span>
          </div>
        </div>
    </div>
  );
}

export default HomePage;