import React, { useState } from "react";
import '../sass/app.scss';
import '../sass/home.scss';
import Password from '../components/password';
import CaseStudyContent from '../pages/case_studydesign_content';

function StudyDesign() {

    const [isToggled, setIsToggled] = useState(true);

    const updateToggleState = (newState) => {
      setIsToggled(newState);
    };

  return (
    <div>
        {isToggled ? <div className='password-overlay'> <Password updateToggleState={updateToggleState} /> </div> : <CaseStudyContent />}        
    </div>
  );
}

export default StudyDesign;